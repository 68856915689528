import { createMuiTheme } from '@material-ui/core/styles'
import { heading } from '../../utils/utils'

const mainFont = 'MuseoSans'
const titleFont = 'Fiona'
//const surtitlesFont = 'fashionDidot'
const backFont = 'sans-serif'

const white = '#ffffff'
const black = '#3f2b2f'

const primaryMain = '#CE0037' //rose couleur principale (boutons etc)
const secondaryMain = '#A68340' //dorée pas utilisée pour le moment
const pink = '#e50045' //rose utilisé pour le header et autres
const grey = '#3E2B2E'
const greyDark = '#343a40'
const greyMedium = '#343a40'
const greyLight = '#6c757d'
const greyBorder = '#f7f7f7'
const greyButtonDisabled = '#d8d8d8'
//const red = '#B33922'
const green = '#008000'
//const orange = '#F74002'

//const imp = '!important'

// const f11 = `11px`
const f12 = `12px`
const f13 = `13px`
const f14 = `14px`
//const f15 = `15px`
const f16 = `16px`
const f18 = `18px`

// const f20 = `20px`
// const f22 = `22px`
//const f24 = `24px`
const f30 = `30px`
//const f32 = `32px`
//const f42 = `42px`
const f45 = '45px'
const f70 = '70px'

const body = {
  fontSize: f14,
  lineHeight: 1.6,
}
const body2 = {
  fontSize: f13,
  lineHeight: 1.67,
}

/* const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}*/

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  themeFont: {
    main: mainFont,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: `${mainFont}, sans-serif`,
    h1: {
      fontFamily: `${titleFont}, ${backFont}`,
      fontSize: `${f70} !important`,
      lineHeight: '1.2!important',
      fontWeight: 500,
      textTransform: 'uppercase',
      fontStyle: 'normal!important',
      color: `${white} !important`,
    },
    h2: {
      fontFamily: `${titleFont}, ${backFont}`,
      fontSize: 35,
      lineHeight: 1.2,
      fontWeight: 300,
      textTransform: 'uppercase',
      fontStyle: 'normal',
      color: `${primaryMain} !important`,
    },
    h3: {
      fontFamily: `${titleFont}, ${backFont}`,
      fontSize: 35,
      lineHeight: 1.2,
      fontWeight: 300,
      textTransform: 'uppercase',
      fontStyle: 'normal',
      color: `${primaryMain} !important`,
    },
    h4: {
      lineHeight: 1.55,
      ...heading(f16, f16, 600, 600, titleFont, backFont, false),
    },
    h5: {
      lineHeight: 1.25,
      ...heading(f16, f16, 600, 600, titleFont, backFont, false),
    },
    h6: {
      lineHeight: 1.25,
      ...heading(f16, f16, 600, 600, titleFont, backFont, false),
    },
    body1: {
      ...body,
    },
    body2: {
      ...body2,
    },
    caption: {
      fontSize: f12,
      lineHeight: 1.17,
      fontWeight: 400,
    },
    button: {
      fontSize: f18,
      fontWeight: 300,
      lineHeight: f18,
      textTransform: 'uppercase',
    },
  },
  palette: {
    common: {
      black: black,
      white: white,
    },
    background: {
      default: white,
      paper: white,
    },
    primary: {
      main: primaryMain,
    },
    secondary: {
      main: secondaryMain,
    },
    success: {
      main: '#47E169',
    },
    error: {
      main: '#FA3232',
    },
    warning: {
      main: '#FF8333',
    },
    divider: greyLight,
    primaryTransparent: {
      main: secondaryMain,
      light: greyLight,
    },
    secondaryTransparent: {
      main: secondaryMain,
      light: greyLight,
    },
    ternary: {
      main: '#0082C3',
      light: '#F1FAFF',
    },
    accent: {
      main: '#FFEA28',
    },
  },
  sizes: {
    headerHeight: 60,
    headerHeightTransparent: 90,
    logoWidth: 140,
    logoHeight: 30,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: grey,
    greyLight: greyLight,
    greyMedium: greyMedium,
    greyText: greyDark,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  spacing: 8,
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '14px 10px',
        borderRadius: 0,
        textTransform: 'uppercase',
        height: '50px',
        width: '100%',
        maxWidth: '400px',
      },
      sizeSmall: {
        padding: '6px 10px',
        lineHeight: 1.6,
      },
      sizeLarge: {
        padding: '14px 10px',
      },
      contained: {
        boxShadow: 'none',
        '&:hover, &:focus, &:active, &.selected': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        color: `${primaryMain} !important`,
        backgroundColor: `${white} !important`,
        borderColor: `${primaryMain} !important`,
        borderWidth: '2px',
        borderStyle: 'solid',

        '&:hover, &:focus, &:active, &.selected': {
          color: `${white} !important`,
          backgroundColor: `${primaryMain} !important`,
        },
        '&.Mui-disabled': {
          backgroundColor: `${greyButtonDisabled} !important`,
          color: `${white} !important`,
          border: 'none !important',
        },
      },
      outlined: {
        padding: '10px 20px',
      },
      outlinedSizeLarge: {
        padding: '10px 40px',
      },
      outlinedSizeSmall: {
        padding: '5px 10px',
        lineHeight: 1.6,
      },
    },
    MuiPaper: {
      root: {
        boxShadow: '0px 0px 15px rgba(46, 46, 46, 0.1)',
      },
      rounded: {
        borderRadius: 0,
      },
    },
    MuiSelect: {
      root: {
        color: primaryMain,
      },
      selectMenu: {
        '&:focus': {
          background: white,
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
        fontSize: 15,
        fontWeight: 700,
        color: black,
        backgroundColor: 'transparent',
        height: 25,
        padding: 0,
      },
      label: {
        padding: 0,
      },
      sizeSmall: {
        height: 22,
        fontSize: 12,
        fontWeight: 400,
      },
      labelSmall: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      colorPrimary: {
        color: primaryMain,
        backgroundColor: 'transparent',
      },
      colorSecondary: {
        color: secondaryMain,
        backgroundColor: 'transparent',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '.hero__title': {
          fontSize: `${f45} !important`,
          lineHeight: '1.2!important',
          fontWeight: 500,
          textTransform: 'uppercase',
          fontStyle: 'normal!important',
          color: `${primaryMain} !important`,
        },
        '.banner': {
          backgroundColor: `${pink} !important`,
          color: `${white} !important`,
          '& div,& p': {
            fontFamily: `${mainFont}, ${backFont} !important`,
          },
        },
        '.contact-page': {
          backgroundColor: `${white} !important`,
        },
        '.information,.shipping,.payment': {
          backgroundColor: `${white} !important`,
        },
        select: {
          backgroundColor: `${greyBorder} !important`,
        },
        '.input': {
          backgroundColor: `${greyBorder} !important`,
        },
        '.geosuggest__input': {
          backgroundColor: `${greyBorder} !important`,
        },
        '.banner>.MuiTypography-h5': {
          fontWeight: 400,
        },
        '.page__hero h1': {
          color: `${primaryMain} !important`,
        },
        header: {
          backgroundColor: `${white} !important`,
        },
        '.header__logo': {
          height: '70px !important',
        },
        '.footer__top,.footer__top__title': {
          backgroundColor: `${pink} !important`,
          color: `${white} !important`,
          fontFamily: `${mainFont}, ${backFont} !important`,
        },
        '.section-infos__cta': { borderRadius: '0px!important' },
        '.footer__bottomRow1,.footer__bottomRow2,.footer__bottomRow1>div': {
          flexDirection: 'row-reverse',
        },
        '.footer__bottomRow2>:nth-child(1)': {
          justifyContent: 'flex-end !important',
        },
        '.footer__bottomRow2>.footer__bottomRow2Col': {
          flex: 'auto',
        },
        '.footer__menu': {
          textAlign: 'left',
          display: 'flex !important',
          justifyContent: 'flex-start !important',
          flexWrap: 'wrap',
          columnGap: '16px !important',

          '&>a': {
            margin: '0px !important',
          },
        },
        '.section-infos__content>a,.hero__home-box>.ctas>a': {
          display: 'block!important',
          width: '100%',
          maxWidth: '400px',
        },
        '.section-infos__content>a': {
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        '.section-infos__content>h2,.section-infos__content>h3': {
          fontSize: '20px!important',
          lineHeight: '1.2!important',
          fontWeight: '300 !important',
          textTransform: 'uppercase',
          fontStyle: 'normal',
          color: `${grey} !important`,
        },
        '.shop': {
          '&__planner': {
            display: `none !important`,
          },
        },
        '.date_form': {
          background: `none !important`,
          display: `none !important`,
        },
        '&__rental-summary, & .deliveryDate, & .returnDate': {
          display: `none !important`,
        },
        '.menu__link': {
          fontSize: f18,
        },
        '.menu__submenu': {
          fontSize: f16,
          minWidth: 'max-content !important',
          '&>ul': {
            marginRight: '20px !important',
          },
        },
        '.hiw__head': {
          minHeight: '25px!important',
        },
        '.hiw__icon>img': {
          width: '60px!important',
        },
        '.takeBack': {
          backgroundColor: `${white} !important`,
        },
        'takeBack__nav__step--active': {
          backgroundColor: `${secondaryMain} !important`,
        },
        '.productCard': {
          '&:hover, &:focus, &:active, &.selected': {
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,.15)!important',
          },
        },
        '.funnel': {
          '&__header': {
            color: `${white} !important`,
            backgroundColor: `${green} !important`,
            '&>h1': {
              color: `${black} !important`,
            },
          },
          '&__rental-summary, & .deliveryDate, & .returnDate': {
            display: `none !important`,
          },
          '&__column': {
            '&--delivery': {
              flexBasis: `100% !important`,
              maxWidth: `100% !important`,
            },
            '&--return': {
              display: 'none !important',
            },
          },
        },
        '.input--ayden': {
          backgroundColor: `${greyBorder} !important`,
          color: `${black} !important`,
        },
        '.product__head': {
          flexDirection: 'column',
          alignItems: 'flex-start !important',
          textTransform: 'capitalize',
          '& h1': {
            fontSize: `${f45} !important`,
            lineHeight: '1.2!important',
            fontWeight: 500,
            color: `${primaryMain} !important`,
            textTransform: 'capitalize',
          },
          '& h4': {
            fontSize: `${f16} !important`,
            fontWeight: 600,
            color: `${black} !important`,
          },
        },
        '.product__delete': {
          width: 'fit-content !important',
        },
        '.account': {
          backgroundColor: `${white} !important`,
        },
        '.account-card': {
          borderColor: `${greyBorder} !important`,
          borderWidth: '2px',
          borderStyle: 'solid',
        },
        '.account__desc,.account__desc>p,.account__desc>p>strong,.account__desc>b,.account__switch':
          {
            fontSize: `${f16} !important`,
            fontWeight: 400,
            textTransform: 'uppercase',
            textAlign: 'center',
          },
        '.account__box,.account__fields,.takeBack-searchRef__input,.input--ayden':
          {
            '& .label': {
              fontSize: `${f13} !important`,
              '&+div': {
                backgroundColor: `${greyBorder} !important`,
                borderColor: `${greyLight} !important`,
                borderWidth: '1px',
                borderStyle: 'solid',
                overflow: 'hidden',
              },
            },
            '& input': {
              backgroundColor: `${greyBorder} !important`,
              border: 'none',
              borderWidth: '0px !important',

              '&:hover, &:focus,&:focus-visible, &:active, &.selected': {
                border: 'none',
                borderWidth: '0px !important',
                outline: 'none',
              },
            },
            '& select': {
              backgroundColor: `${greyBorder} !important`,
              borderColor: `${greyLight} !important`,
              borderWidth: '1px',
              borderStyle: 'solid',
              overflow: 'hidden',
              borderRadius: '9px!important',
            },
          },
        '.payment-bycard': {
          '& input': {
            backgroundColor: `${greyBorder} !important`,
            border: 'none',
            borderWidth: '0px !important',
          },
        },
        '.takeBack__nav__step': {
          backgroundColor: `${secondaryMain} !important`,
        },
        '.takeBack-faq__section': {
          maxWidth: '768px !important',
          marginLeft: 'auto !important',
          marginRight: 'auto !important',
          borderColor: `${greyBorder} !important`,
          borderTopWidth: '2px',
          borderTopStyle: 'solid',
          paddingTop: '40px',
          '& strong': {
            fontWeight: '400 !important',
          },
        },
        '.takeBack__preliminaryQuestion__title,.takeBack-faq__title': {
          fontFamily: `${mainFont}, ${backFont}`,
          fontSize: `${f30} !important`,
          marginBottom: '10px !important',
        },
        '.takeBack__preliminaryQuestion__text': {
          fontSize: `${f16} !important`,
        },
        '.takeBack__required-field': {
          fontSize: `${f14} !important`,
        },
        '.takeBack__add-product-btn': {
          backgroundColor: `${white} !important`,
          color: `${secondaryMain} !important`,
        },
        '.product__price--original': {
          fontSize: '20px !important',
          borderLeft: `1px solid ${primaryMain} !important`,
        },
        '.commitment': {
          backgroundColor: `${white} !important`,
        },
        '.faq-page__cat': {
          fontFamily: `${mainFont}, ${backFont}`,
          fontSize: `${f30} !important`,
        },
        '.faq-page': {
          backgroundColor: `${white} !important`,
          '& h1': {
            fontSize: `${f45} !important`,
            lineHeight: '1.2!important',
            fontWeight: 500,
            textTransform: 'uppercase',
            fontStyle: 'normal!important',
            color: `${primaryMain} !important`,
          },
        },
        '.faq__question': {
          maxWidth: 'none !important',
          //maxWidth: '480px !important',
        },
        '.faq__btn': {
          border: 'none !important',
        },
        '.sizes__list': {
          '&>button': {
            maxWidth: 'none !important',
            width: 'max-content !important',

            color: `${primaryMain} !important`,
            backgroundColor: `${white} !important`,
            borderColor: `${primaryMain} !important`,
            borderWidth: '2px',
            borderStyle: 'solid',

            '&:hover, &:focus, &:active, &.selected': {
              color: `${white} !important`,
              backgroundColor: `${primaryMain} !important`,
            },
          },
        },
        '.cart': {
          backgroundColor: `${white} !important`,
        },
        '.funnel__header': {
          backgroundColor: `${white} !important`,
          color: `${secondaryMain} !important`,
          '&>h1': {
            backgroundColor: `${white} !important`,
            color: `${secondaryMain} !important`,
          },
        },
      },
    },
  },
})

export default theme
